import Modal from "react-bootstrap/Modal";
import React from "react";
import moment from "moment/moment";

const UserInfoModal = ({ show, onHide, user }) => {
  return (
    <Modal show={show} onHide={onHide} className="_modalWidth">
      <div className="main-body ">
        <div className="page-wrapper">
          <div className="row ">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header bg-light">
                  <h5>User Details</h5>
                  <i
                    className="feather icon-x float-right c_pointer"
                    onClick={onHide}
                  ></i>
                </div>
                <div className="card-block table-border-style">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Contact</th>
                          <th>Email</th>
                          <th>Account Created</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {user.firstName} {user.lastName}
                          </td>

                          <td>{user.phone}</td>
                          <td>{user.email}</td>
                          <td>
                            {moment(user.createdAt).format("MMMM Do YYYY")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-sm-12">
              <div className="card">
                <div className="card-header _modalStyles">
                  <h5>Edit</h5>
                </div>
                <div className="card-body ">
                  <h5> </h5>

                  <div className="row">
                    <div className="col-md-6">
                      <form>
                        <div className="form-group">
                          <label for="name">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="InputName"
                            placeholder="Enter Name"
                          />
                        </div>
                        <div className="form-group">
                          <label for="contact">Contact</label>
                          <input
                            type="number"
                            className="form-control"
                            id="contact"
                            placeholder="Contact number"
                          />
                        </div>
                      </form>
                    </div>
                    <div className="col-md-6">
                      <form>
                        <div className="form-group">
                          <label for="exampleInputEmail1">Email address</label>
                          <input
                            type="email"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Enter email"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className="pcoded-content w-100">
        <div className="row">
          <div className="col-6">
            <div className="card daily-sales">
              <div className="card-block">
                <h5 className="mb-4 d-flex">
                  <AiOutlineCar size={22} className="mr-2" /> Total Rides Taken
                </h5>
                <div className="row d-flex align-items-center">
                  <div className="col-9 d-flex align-items-center">
                    <AiOutlineNumber className="mr-2" size={30} />
                    <h3 className="f-w-300 d-flex align-items-center m-b-0 text-success">
                      49
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card Monthly-sales">
              <div className="card-block">
                <h5 className="mb-4 d-flex">
                  <TbReportMoney size={22} className="mr-2" /> Total Expenditure
                </h5>
                <div className="row d-flex align-items-center">
                  <div className="col-9 d-flex align-items-center">
                    <BiDollar className="mr-2" size={30} />
                    <h3 className="f-w-300 d-flex align-items-center  m-b-0 text-success">
                      942.32
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </Modal>
  );
};

export default UserInfoModal;
