import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { AiOutlineCar, AiOutlineNumber } from "react-icons/ai";
import { TbReportMoney } from "react-icons/tb";
import { BiDollar } from "react-icons/bi";
import { TiTick } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";
import { GrAtm } from "react-icons/gr";
import { BsCashCoin } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import PaymentDetail from "./PaymentDetails/PaymentDetail";

import moment from "moment/moment";
import axios from "../../axios";

const DriverDetailsModal = ({ show, onHide, driver }) => {
  const [data, setData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 5;

  useEffect(() => {
    axios.get("/driver/getAllBookings/" + driver?._id).then((res) => {
      setData(res.data);
    });
  }, []);

  useEffect(() => {
    axios.get("/stripe/getUserBalance/" + driver?._id).then((res) => {
      setPaymentData(res.data);
    });
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data?.bookings?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data?.bookings?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data?.bookings?.length;
    setItemOffset(newOffset);
  };

  if (!driver) return;
  return (
    <Modal show={show} onHide={onHide} className="_modalWidth">
      <div className="main-body " style={{ backgroundColor: "#f4f7fa" }}>
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header bg-light">
                  <h5>Driver Details</h5>
                  <i
                    className="feather icon-x float-right c_pointer"
                    onClick={onHide}
                  ></i>
                </div>
                <div className="card-block table-border-style">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>First Name</th>
                          <th>Contact</th>
                          <th>Email</th>
                          <th>Date of Joining</th>
                          <th>Account Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {driver.firstName} {driver.lastName}
                          </td>

                          <td>{driver.phone}</td>
                          <td>{driver.email}</td>
                          <td>
                            {moment(driver.createdAt).format("MMMM Do YYYY")}
                          </td>

                          <td>
                            {driver.isAccountVerified
                              ? "Verified"
                              : "Unverified"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="pcoded-content w-100">
              <div className="row">
                <div className="col-md-6">
                  <div className="card daily-sales">
                    <div className="card-block">
                      <h5 className="mb-4 d-flex">
                        <AiOutlineCar size={22} className="mr-2" /> Total Rides
                      </h5>
                      <div className="row d-flex align-items-center">
                        <div className="col-9 d-flex align-items-center">
                          <AiOutlineNumber className="mr-2" size={30} />
                          <h3 className="f-w-300 d-flex align-items-center m-b-0 text-success">
                            {data?.bookings?.length || 0}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card daily-sales">
                    <div className="card-block">
                      <h5 className="mb-4 d-flex text-success">
                        {" "}
                        <TiTick size={22} className="mr-1" />
                        Completed
                      </h5>
                      <div className="row d-flex align-items-center">
                        <div className="col-9">
                          <h3 className="f-w-300 d-flex align-items-center m-b-0">
                            {data?.completedRides || 0}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card Monthly-sales">
                    <div className="card-block">
                      <h5 className="mb-4 d-flex text-danger">
                        <RxCross2 size={22} className="mr-1" /> Missed
                      </h5>
                      <div className="row d-flex align-items-center">
                        <div className="col-9">
                          <h3 className="f-w-300 d-flex align-items-center  m-b-0">
                            {data?.cancelledRides || 0}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-md-4">
                  <div className="card daily-sales">
                    <div className="card-block">
                      <h5 className="mb-4 d-flex text-danger">
                        <GrAtm size={18} className="mr-1" />
                        Pending
                      </h5>
                      <div className="row d-flex align-items-center">
                        <div className="col-9 d-flex align-items-center">
                          <BiDollar className="mr-2" size={30} />
                          <h3 className="f-w-300 d-flex align-items-center m-b-0 text-success">
                            {paymentData?.balance?.pending?.amount / 100 || 0}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card daily-sales">
                    <div className="card-block">
                      <h5 className="mb-4 d-flex text-success">
                        <BsCashCoin size={18} className="mr-1" />
                        Avaliable
                      </h5>
                      <div className="row d-flex align-items-center">
                        <div className="col-9 d-flex align-items-center">
                          <BiDollar className="mr-2" size={30} />
                          <h3 className="f-w-300 d-flex align-items-center m-b-0 text-success">
                            {paymentData?.balance?.available?.amount / 100 || 0}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card Monthly-sales">
                    <div className="card-block">
                      <h5 className="mb-4 d-flex">
                        <TbReportMoney size={22} className="mr-2" /> Total
                        Earnings
                      </h5>
                      <div className="row d-flex align-items-center">
                        <div className="col-9 d-flex align-items-center">
                          <BiDollar className="mr-2" size={30} />
                          <h3 className="f-w-300 d-flex align-items-center  m-b-0 text-success">
                            {(paymentData?.balance?.pending?.amount +
                              paymentData?.balance?.available?.amount) /
                              100 || 0}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <PaymentDetail paymentData={data} /> */}
            </div>

            <div className="col-xl-12">
              <div className="card">
                <div className="card-header bg-light">
                  <h5>Ride Details</h5>
                </div>
                <div className="card-block table-border-style">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>User</th>
                          <th>Pick Up </th>
                          <th>Destination</th>
                          <th>Vehicle Drop Off</th>
                          <th>Status</th>

                          <th>Fair</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems?.map((ride, i) => (
                          <tr>
                            <th scope="row"> {++i} </th>
                            <td>
                              {ride?.user?.firstName +
                                " " +
                                ride?.user?.lastName}
                            </td>
                            <td> {ride?.pickup?.address} </td>
                            <td> {ride?.dropOff?.address} </td>
                            <td>{ride?.vehicleDropOff?.address} </td>
                            <td>{ride?.status} </td>

                            <td>${ride?.price.toFixed(2)}</td>
                            <td>
                              {moment(ride?.updatedAt).format("MMMM Do YYYY")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="page-num"
                  previousLinkClassName="page-num"
                  nextLinkClassName="page-num"
                  activeClassName="active"
                />
              </div>
            </div>
            <div className="container">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-header bg-light mb-3">
                    <h5>Documents</h5>

                    <span className="d-block m-t-5">
                      Documents uploaded by driver for verification
                    </span>
                  </div>
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-6 text-center">
                      <h6>Drivers License Front</h6>
                      <div>
                        <a href={driver.drivingLicense?.front} target="_blank">
                          <img
                            src={driver.drivingLicense?.front}
                            crossOrigin="anonymous"
                            alt="License Front NA"
                            className="_documents"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-md-6 text-center">
                      <h6>Drivers License Back</h6>
                      <div>
                        <a href={driver.drivingLicense?.back} target="_blank">
                          <img
                            src={driver.drivingLicense?.back}
                            crossOrigin="anonymous"
                            alt="License Front NA"
                            className="_documents"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-6 text-center">
                      <h6 className="mt-4">Proof of ID</h6>
                      <div>
                        <a href={driver?.proofOfID} target="_blank">
                          <img
                            src={driver?.proofOfID}
                            crossOrigin="anonymous"
                            alt="Proof of ID NA"
                            className="justify-content-center align-items-center _documents"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-md-6 text-center">
                      <h6 className="mt-4">Criminal Background</h6>
                      <div>
                        <a href={driver?.criminalBackground} target="_blank">
                          <img
                            src={driver?.criminalBackground}
                            crossOrigin="anonymous"
                            alt="Criminal Background NA"
                            className="justify-content-center align-items-center _documents"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 text-center">
                      <h6 className="mt-4">WWCC</h6>
                      <div>
                        <a href={driver?.WWCC} target="_blank">
                          <img
                            src={driver?.WWCC}
                            crossOrigin="anonymous"
                            alt="WWCC NA"
                            className="justify-content-center align-items-center _documents"
                          />{" "}
                        </a>
                      </div>
                    </div>
                    <div className="col-md-6 text-center">
                      <h6 className="mt-4">NSW</h6>
                      <div>
                        <a href={driver?.NSW} target="_blank">
                          <img
                            src={driver?.NSW}
                            crossOrigin="anonymous"
                            alt="NSW NA"
                            className="justify-content-center align-items-center _documents"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DriverDetailsModal;
