import Axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const user = cookies.get("user");
const axios = Axios.create({
  // baseURL: "https://r5vw6hz5-4000.inc1.devtunnels.ms/v1",
  // baseURL: "https://api.dadss.com.au/v1",
  baseURL: "https://api.dadss.com/v1",
  // baseURL: "http://localhost:4000/v1",
  // baseURL: "https://n0xv4mzw-4000.euw.devtunnels.ms/v1",
  headers: { Authorization: `Bearer ${user?.tokens?.access?.token}` },
});

export default axios;
