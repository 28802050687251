import React from "react";

const TryAgain = () => {
  return (
    <div className="container mt-5">
      <p className="display-4 text-center">Sorry, Try again!</p>
    </div>
  );
};

export default TryAgain;
