import "./App.css";
import "./assets/css/style.css";
import AdminDashboard from "./components/AdminDashboard";
import DriverInfo from "./components/DriverInfo";
import SideBar from "./components/SideBar";
import UserInfo from "./components/UserInfo";
import { Route, Routes, Navigate } from "react-router-dom";
import SignIn from "./components/SignIn";
import { ToastContainer } from "react-toastify";
import Cookies from "universal-cookie";

import "react-toastify/dist/ReactToastify.css";
import AdminProfile from "./components/AdminProfile";
import SuccessPage from "./components/SuccessPage";
import TryAgain from "./components/TryAgain";

function App() {
  const cookie = new Cookies();

  const user = cookie.get("user");

  return (
    <div className="app">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {user ? (
        <>
          <SideBar />
          <Routes>
            <Route path="/" element={<AdminDashboard />} />
            <Route path="/driver_info" element={<DriverInfo />} />
            <Route path="/user_info" element={<UserInfo />} />
            <Route path="/admin_profile" element={<AdminProfile />} />
            <Route path="*" element={<Navigate to={"/"} replace />} />
          </Routes>
        </>
      ) : (
        <Routes>
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/tryagain" element={<TryAgain />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="*" element={<Navigate to={"/login"} replace />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
